<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-8">
              <h3 class="mb-0">
                <span class="text-before-button mr-4">{{ product.name }}</span>
                <base-button
                  v-if="
                    $currentUserCan($permissions.PERM_EDIT_PRODUCTS) &&
                    product.status != STATUS_ACTIVE
                  "
                  type="success"
                  icon
                  size="sm"
                  :title="$t('PRODUCTS.ACTIVATE')"
                  @click="activate()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-check"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="
                    $currentUserCan($permissions.PERM_EDIT_PRODUCTS) &&
                    product.status != STATUS_INACTIVE &&
                    false
                  "
                  type="warning"
                  icon
                  size="sm"
                  :title="$t('PRODUCTS.DEACTIVATE')"
                  @click="deactivate()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-times"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="
                    $currentUserCan($permissions.PERM_EDIT_PRODUCTS) &&
                    product.status != STATUS_BACKORDER
                  "
                  type="warning"
                  icon
                  size="sm"
                  :title="$t('PRODUCTS.SET_BACKORDER')"
                  @click="
                    () => {
                      backorderModalOpened = true;
                      replacements = [];
                    }
                  "
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-random"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="$currentUserCan($permissions.PERM_EDIT_PRODUCTS)"
                  type="primary"
                  icon
                  size="sm"
                  @click="editProduct()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="$currentUserCan($permissions.PERM_DELETE_PRODUCTS)"
                  type="danger"
                  icon
                  size="sm"
                  @click="deleteProduct()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-trash"></i>
                  </span>
                </base-button>
              </h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack()"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>

              <notification-subscription
                :objectType="'products'"
                :objectId="product.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 justify-content-center justify-content-sm-between flex-wrap tabs-wrapper"
          >
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="global"
              :key="renderKey"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="true">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <product-view-global :product="product" />
                </tab-pane>

                <tab-pane title="gallery" id="2">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GALLERY") }}
                  </span>
                  <product-view-gallery :product="product" />
                </tab-pane>

                <tab-pane
                  title="supplier-products"
                  id="3"
                  v-if="product.can_be_purchased"
                >
                  <span slot="title">
                    <i class="ni ni-circle-08" />
                    {{ $t("COMMON.SUPPLIERS") }}
                  </span>
                  <product-view-suppliers :product="product" />
                </tab-pane>

                <tab-pane
                  title="files"
                  id="998"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
                >
                  <span slot="title">
                    <i class="ni ni-folder-17" />
                    {{ $t("COMMON.FILES") }}
                  </span>
                  <list-file-component :object="product" />
                </tab-pane>

                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <product-view-logs :product="product" />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>

    <modal
      :show.sync="backorderModalOpened"
      modal-classes="modal-secondary"
      size="xl"
    >
      <div>
        <h2>
          {{ $t("PRODUCTS.SELECT_REPLACEMENTS") }}
        </h2>
        <div>
          <base-input
            :label="`${$t('PRODUCTS.RETURN_TO_STOCK_DATE')} (*)`"
            :placeholder="$t('PRODUCTS.RETURN_TO_STOCK_DATE')"
          >
            <flat-picker
              :config="{
                allowInput: true,
                minDate: 'today',
                locale: $flatPickrLocale(),
              }"
              class="form-control datepicker"
              v-model="backOrderData.return_to_stock_date"
            >
            </flat-picker>
          </base-input>

          <base-input
            :label="`${$t('COMMON.PRODUCTS')}`"
            :placeholder="$t('COMMON.PRODUCTS')"
          >
            <products-selector
              :products="[]"
              :filterIdsNotIn="[product.id]"
              :filterable="true"
              :showAll="false"
              :multiple="true"
              :filterIsModel="true"
              :organization="product.organization.id"
              @productChanged="
                (products) => {
                  backOrderData.replacements = products.map((item) => item.id);
                }
              "
            />
          </base-input>

          <div class="my-4">
            <base-button
              type="button"
              class="btn btn-sm btn-primary"
              native-type="submit"
              @click="setBackorder()"
            >
              {{ $t("PRODUCTS.SET_BACKORDER") }}
            </base-button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import flatPicker from "vue-flatpickr-component";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import {
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_BACKORDER,
} from "@/constants/products";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import defaultProduct from "./defaultProduct";
import ProductViewGlobal from "./partials/ProductViewGlobal.vue";
import ProductViewLogs from "./partials/ProductViewLogs.vue";
import ProductViewGallery from "./partials/ProductViewGallery.vue";
import ProductViewSuppliers from "./partials/ProductViewSuppliers.vue";
import ProductsSelector from "@/components/ProductsSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    ProductViewGlobal,
    ProductViewGallery,
    ProductViewLogs,
    ListFileComponent,
    NotificationSubscription,
    ProductViewSuppliers,
    ProductsSelector,
    flatPicker,
  },

  mixins: [requestErrorMixin],

  data() {
    return {
      renderKey: 0,
      product: cloneDeep(defaultProduct),
      backorderModalOpened: false,
      backOrderData: {
        replacements: [],
        return_to_stock_date: null,
      },
      STATUS_ACTIVE,
      STATUS_INACTIVE,
      STATUS_BACKORDER,
      console: console,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("products/get", id);
        this.product = this.$store.getters["products/product"];
        this.renderKey++;

        if (this.product.status == STATUS_BACKORDER) {
          await swal.fire({
            title: this.$t("PRODUCTS.PRODUCT_IN_BACKORDER"),
            text: this.product.return_to_stock_date
              ? this.$t("PRODUCTS.PRODUCT_BACK_IN_STOCK_ON_X", {
                  date: this.$timeZoneDateFormat(
                    this.product.return_to_stock_date,
                    "LL"
                  ),
                })
              : "",
            icon: "warning",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editProduct() {
      this.$router.push({
        name: "Edit Product",
        params: { id: this.product.id },
      });
    },

    async deleteProduct() {
      const confirmation = await swal.fire({
        title: this.$t("PRODUCTS.DELETE_THIS_PRODUCT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("products/destroy", this.product.id);
          await this.goBack();
          this.$notify({
            type: "success",
            message: this.$t("PRODUCTS.PRODUCT_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async activate() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        await this.$store.dispatch("products/activate", this.product.id);
        this.product = this.$store.getters["products/product"];
        this.$notify({
          type: "success",
          message: this.$t("PRODUCTS.PRODUCT_ACTIVATED"),
        });
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
      swal.close();
    },

    async deactivate() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        await this.$store.dispatch("products/deactivate", this.product.id);
        this.product = this.$store.getters["products/product"];
        this.$notify({
          type: "success",
          message: this.$t("PRODUCTS.PRODUCT_DEACTIVATED"),
        });
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
      swal.close();
    },

    async setBackorder() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        await this.$store.dispatch("products/setBackorder", {
          id: this.product.id,
          replacements: this.backOrderData.replacements,
          return_to_stock_date: this.backOrderData.return_to_stock_date
            ? moment(this.backOrderData.return_to_stock_date).toISOString()
            : null,
        });
        this.product = this.$store.getters["products/product"];
        this.$notify({
          type: "success",
          message: this.$t("PRODUCTS.PRODUCT_SET_BACKORDER"),
        });
        this.backOrderData = {
          replacements: [],
          return_to_stock_date: [],
        };
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
      this.backorderModalOpened = false;
      swal.close();
    },

    goBack() {
      this.$router.push({ name: "List Products" });
    },
  },
};
</script>
